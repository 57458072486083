.booking {
  padding: 2rem;
  border-radius: .5rem;
  border: 1px solid rgb(229, 231, 235);
  position: sticky;
  top: 80px;
}

.booking__form {
  padding-top: 2rem;
}

.booking__info-form {
  padding: 1rem;
  border: 1px solid rgb(229, 231, 235);
  margin-bottom: 1rem;
}

.booking__form-input {
  width: 100%;
  padding: 0.5rem;
  border-radius: .5rem;
  color: var(--heading-color);
  font-size: 1rem;
  border-bottom: 1px solid rgb(229, 231, 235);
  border: none;
}

.booking__form-input:focus {
  outline: none;
}

.transfer-info {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px; /* Adds space between the items */
  font-size: 1rem;
  color: var(--text-color);
  font-weight: 500;
  margin-bottom: 1rem;
}

.transfer-info__text {
  margin: 0; /* Remove default margins */
  font-weight: 600;
  color: var(--text-color);
}

.btn.primary__btn {
  background-color: var(--primary-color);
  color: white;
  padding: 10px 20px;
  border-radius: .5rem;
  font-weight: 600;
  text-align: center;
  display: block;
  width: 100%;
  margin-top: 1rem;
}

.btn.swap-btn {
  background-color: #FFA500; /* This is the orange color from your image */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px; 
  font-weight: bold;
  font-size: 0.9rem;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1); 
  cursor: pointer;
  transition: background-color 0.3s ease; 
  }
  
  .btn.swap-btn:hover {
  background-color: #e69500; /* Darker shade of orange for hover effect */
  }

  .btn.primary__btn {
    background-color: var(--primary-color);
    color: white;
    padding: 10px 20px;
    border-radius: .5rem;
    font-weight: 600;
    text-align: center;
    display: block;
    width: 100%;
    margin-top: 1rem;
    }
    
    @media (max-width: 768px) {
      .transfer-info {
      flex-direction: column;
      gap: 5px;
      }
      .btn.swap-btn {
        padding: 8px 16px; /* Slightly smaller padding on smaller screens */
    }
    }

/* Add any additional styling as needed */
